export default class ApiService {
    constructor() {
        this.origin = window.location.origin;
        this.hostname = process.env.VUE_APP_TEST_HOST_NAME || window.location.hostname;
        this.hostname_local = process.env.VUE_APP_TEST_HOST_NAME || window.location.hostname.split(".")[0]
        this.store_id = localStorage.getItem(this.hostname);
    }
    async fetch_and_setup_store_id() {
        const targetUrl = `${process.env.VUE_APP_API_HOST_NAME}/api/website/store?type=main&store_url_local_sub=${this.hostname_local}&store_id=null&store_url=${this.hostname}`;
        const {
            data: {
                store: { id },
            },
        } = await fetch(targetUrl).then(response => response.json());
        this.store_id = id;
        localStorage.setItem(this.hostname, this.store_id);
        return id;
    }
    async request({ method, url, params = {}, headers = {} }) {
        this.store_id = localStorage.getItem(this.hostname);
        const urlParams = new URLSearchParams(window.location.search);
        const is_preview_mode = urlParams.get("is_preview_mode");
        const id = !is_preview_mode ? this.store_id || (await this.fetch_and_setup_store_id()) : null;
        if (!this.store_id) {
            this.store_id = id;
        }
        if (method === "get") {
            const urlParams = params ? new URLSearchParams({ store_id: id, ...params }).toString() : "";
            try {
                return fetch(`${url}?${urlParams}`, { headers }).then(res => res.json());
            } catch (err) {
                console.error(err);
                return new Error(err);
            }
        } else {
            try {
                return fetch(url, { method, body: JSON.stringify({ store_id: id, ...params }), headers }).then(res => res.json());
            } catch (err) {
                console.error(err);
                return new Error(err);
            }
        }
    }

    async get({ url, params }) {
        return await this.request({ url, params, method: "get" });
    }
    async post({ url, params }) {
        return await this.request({ url, params, method: "POST" });
    }
}
